<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8" class="pt-0">
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar :color="headerBgColour">
                <v-toolbar-title class="white--text">
                  Open Tickets
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-chip :color="chipColour" class="white--text">
                  {{ openTicketCount }}
                </v-chip>
              </v-toolbar>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-toolbar :color="headerBgColour">
                <v-toolbar-title class="white--text">
                  On Hold Tickets
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-chip :color="chipColour" class="white--text">
                  {{ onHoldTicketCount }}
                </v-chip>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-for="(innerArray, index) in usersFormatted" :key="index">
          <v-col v-for="(user, index) in innerArray" :key="user.zohoId">
            <v-card>
              <v-toolbar>
                <v-toolbar-title>
                  <v-icon> mdi-face-agent </v-icon>
                  {{ user.firstName + " " + user.lastName }}
                  <br />
                  <small class="ml-7 grey--text lighten-2"
                    >Tickets Today:
                    <b>{{ getUserTicketsTotalToday(user) }}</b> - Time Logged
                    Today:
                    <b>{{ user.totalTimeSpentToday }} Mins</b></small
                  >
                </v-toolbar-title>
                <v-spacer> </v-spacer>
                <v-chip color="info">
                  {{ getUserTickets(user).length }}
                </v-chip>
              </v-toolbar>
              <v-list>
                <v-list-item
                  v-for="(ticket, index) in getUserTickets(user)"
                  :key="ticket.ticketNumber"
                >
                  <v-list-item-content>
                    #{{ ticket.ticketNumber }} -
                    {{ ticket.companyName }} -
                    {{ ticket.subject }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="getUserTickets(user).length == 0">
                  <v-list-item-content>
                    <b class="danger--text"> Not Working On Anything </b>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="pt-0">
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar :color="headerBgColour">
                <v-toolbar-title class="white--text">
                  Waiting Tickets - Support
                </v-toolbar-title>
                <v-spacer> </v-spacer>
                <v-chip :color="chipColour" class="white--text">
                  {{ waitingTicketsComp.length }}
                </v-chip>
              </v-toolbar>
              <v-list>
                <v-list-item
                  v-for="(ticket, index) in waitingTicketsComp"
                  :key="ticket.ticketNumber"
                >
                  <v-list-item-content>
                    #{{ ticket.ticketNumber }} -
                    {{ ticket.companyName }} -
                    {{ ticket.subject }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar :color="headerBgColour">
                <v-toolbar-title class="white--text">
                  Waiting Tickets - Projects
                </v-toolbar-title>
                <v-spacer> </v-spacer>
                <v-chip :color="chipColour" class="white--text">
                  {{ allProjectTickets.length }}
                </v-chip>
              </v-toolbar>
              <v-list>
                <v-list-item
                  v-for="(ticket, index) in allProjectTickets.filter(
                    (x) => x.status != 'Working On' && x.status != 'Closed'
                  )"
                  :key="ticket.ticketNumber"
                >
                  <v-list-item-content>
                    #{{ ticket.ticketNumber }} -
                    <span v-if="ticket.parentCompanyNameAsString"
                      >{{ ticket.companyName }} -</span
                    >
                    {{ ticket.subject }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created() {
    if (this.$route.query.secret != 6457835209632687883952975) {
      this.$router.push({
        name: "Error",
        params: { code: "401", message: "No Permission" },
      });
    }
  },
  computed: {
    usersFormatted() {
      if (this.dashData.users) {
        var innerArray = [];

        for (
          var i = 0;
          i < this.dashData.users.length;
          i += this.userSplitDifference
        ) {
          innerArray.push(
            this.dashData.users.slice(i, i + this.userSplitDifference)
          );
        }

        // var element = innerArray[innerArray.indexOf(innerArray.find(x => x.lastName == 'Titchener'))];
        // innerArray.splice(innerArray.indexOf(innerArray.find(x => x.lastName == 'Titchener'), 1));
        // innerArray.splice(innerArray.length - 1, 0, element);

        return innerArray;
      }
    },
    getTimeToUpdateAsPercentage() {
      if (this.untilNextUpdate == 0) {
        return 0;
      } else {
        return (this.untilNextUpdate / this.timeToUpdate) * 100;
      }
    },
    allTodaysTickets() {
      if (this.dashData.todaysTickets) {
        return this.dashData.todaysTickets;
      } else {
        return [];
      }
    },
    onHoldTicketCount() {
      return this.allSupportTickets.filter((x) => x.status == "On Hold").length;
    },
    openTicketCount() {
      return this.allSupportTickets.filter((x) => x.status != "On Hold").length;
    },
    allProjectTickets() {
      if (this.dashData.allProjectTickets) {
        return this.dashData.allProjectTickets
          .map((x) => x)
          .filter((x) => x.status != "On Hold");
      } else {
        return [];
      }
    },
    allSupportTickets() {
      if (this.dashData.allSupportTickets) {
        return this.dashData.allSupportTickets;
      } else {
        return [];
      }
    },
    waitingTicketsComp() {
      return this.allSupportTickets.filter(
        (x) =>
          x.active &&
          x.status != "Working On" &&
          x.status != "On Hold" &&
          x.status != "Closed"
      );
    },
    allTimeEntries() {
      return this.dashData.allTimeEntriesToday ?? [];
    },
    allTickets() {
      var allTickets = [];

      if (this.dashData.allSupportTickets) {
        this.dashData.allSupportTickets.forEach((x) => allTickets.push(x));
      }

      return allTickets;
    },
  },
  async mounted() {
    if (this.$route.query.ttu) {
      this.timeToUpdate = this.$route.query.ttu;
    }

    this.getEverything();

    this.outerUpdateTimerId = setInterval(() => {
      this.waitingTickets = [];
      this.lastUpdated = new Date();
      this.untilNextUpdate = 0;
      this.getEverything();
    }, this.timeToUpdate * 1000);

    this.updateTimerId = setInterval(() => {
      this.untilNextUpdate += 1;
    }, 1000);
  },
  methods: {
    getEverything() {
      this.$courier.Open.GetSupportAgentDashData(this.$route.query.secret).then(
        (x) => {
          x.json().then((data) => {
            this.dashData = data;
          });
        }
      );
    },
    getUserTickets(user) {
      return this.allSupportTickets.filter(
        (x) => x.assignedToZohoId == user.zohoId && x.status == "Working On"
      );
    },
    getUserTicketsTotalToday(user) {
      return this.allTodaysTickets.filter(
        (x) => x.assignedToZohoId == user.zohoId
      ).length;
    },
  },
  data() {
    return {
      dashData: {},
      headerBgColour: "#304156",
      chipColour: "#009bdb",
      updateTimerId: 0,
      outerUpdateTimerId: 0,
      lastUpdated: new Date(),
      untilNextUpdate: 0,
      timeToUpdate: 60,

      usersToInclude: [
        "Titchener",
        "Liddiard",
        "Newman",
        "Gatland",
        "Collis",
        "Chu",
      ],
      ticketStatuses: [
        "Open",
        "Escalated",
        "Working On",
        "Waiting to start",
        "New Reply",
        "On Hold",
      ],
      userSplitDifference: 2,
    };
  },
};
</script>

<style>
</style>